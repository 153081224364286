import React, { useEffect, useState } from "react";
import man from "../../assets/subnav/man.png";
import registration from "../../assets/subnav/registration icon.png";
import man_dark from "../../assets/subnav/darkmode/man white.png";
import registration_dark from "../../assets/subnav/darkmode/registration icon white.png";
import { useMediaQuery } from "@mui/material";

import "./BeeHeader.css";
import { baseCEXURL } from "../../services/api";
import { useTheme } from "@emotion/react";
import SubHeader from "./SubHeader";
// import { Link } from 'react-router-dom';

const BeeHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [seltheme, setSeltheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setSeltheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return <SubHeader />;
  return (
    <div
      style={{
        position: "fixed",
        top: `${isMobile ? "128px" : "153px"}`,
        width: "100%",
        zIndex: 999,
        background: "var(--main-body)",
      }}
    >
      <div className="container cover-page">
        <div
          className="row row-cols-1 row-cols-md-6 g-4 up-logos jar-div"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="">
            <a href={`${baseCEXURL}/indexx-exchange/buy-sell/login-honeybee`}>
              <div className="card">
                {seltheme === "dark" ? (
                  <img
                    src={man_dark}
                    className="card-img-top"
                    alt="..."
                    style={{
                      width: "auto",
                    }}
                  />
                ) : (
                  <img
                    src={man}
                    className="card-img-top"
                    alt="..."
                    style={{
                      width: "auto",
                    }}
                  />
                )}
                <div className="card-body">
                  <h5 className="card-title">Log In</h5>
                </div>
              </div>
            </a>
          </div>
          <div className="">
            <a
              href={`${baseCEXURL}/indexx-exchange/buy-sell/get-started-honeybee`}
            >
              <div className="card">
                {seltheme === "dark" ? (
                  <img
                    src={registration_dark}
                    className="card-img-top"
                    alt="..."
                  />
                ) : (
                  <img src={registration} className="card-img-top" alt="..." />
                )}
                <div className="card-body">
                  <h5 className="card-title">Sign up</h5>
                </div>
              </div>
            </a>
          </div>

          {/* <div className="col" style={{ width: "1%", padding:0 }}></div> */}
        </div>
      </div>
    </div>
  );
};

export default BeeHeader;
